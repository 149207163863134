import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  FadeReveal,
  Image,
  FoodMenu,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"services-page"}>
          <div className={"content-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <Header
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_header",
                    defaultValue: "Services",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal triggerOnce={true}>
                  <Image
                    className={"image"}
                    background={true}
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "content_section_image",
                      numToSelect: 1,
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <FoodMenu
            header={""}
            centerNavigation={true}
            showMenuLikes={true}
            disableScheduleAvailability={true}
            cart={false}
            categoryDisplayVariant={"CategoryTwoSides"}
            itemDisplayVariant={"LeftImageHorizontalCard"}
            itemCartModalVariant={"CatalogItemLightbox"}
            menu={{
              schedules: allFishermanBusinessMenuSchedule.nodes,
              categories: allFishermanBusinessMenuCategory.nodes,
              items: allFishermanBusinessMenuItem.nodes,
              modifierSets: allFishermanBusinessMenuModifierSet.nodes,
              modifiers: allFishermanBusinessMenuModifier.nodes,
            }}
            business={{
              id: fishermanBusiness._id,
              type: fishermanBusiness.type,
            }}
          />
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
    allFishermanBusinessMenuModifierSet {
      nodes {
        _id
        minAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier {
      nodes {
        _id
        name
        price
      }
    }
  }
`;
